<template>
  <div class="shop-setting">
    <header-label
      :label="$t('shopsetting.header')"
      prepend-icon="$g_shopsetting" />
    <div class="d-flex flex-wrap">
      <template v-for="setting in settingLists">
        <v-card
          v-if="setting.show"
          :key="setting.id"
          :to="setting.to"
          class="mb-2 mr-2"
          width="198"
          flat>
          <v-card-text>
            <div class="text-center">
              <v-icon
                color="primary"
                class="mb-4"
                size="64">
                {{ setting.icon }}
              </v-icon>
              <p class="title">
                {{ $t(setting.title) }}
              </p>
            </div>
            <p
              v-for="(item, index) in setting.description"
              :key="`shop_setting_description-${index}`"
              class="caption mb-1">
              - {{ $t(item.text) }}
            </p>
          </v-card-text>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ShopSetting',
  data () {
    return {
      settingLists: []
    }
  },
  computed: {
    ...mapGetters({
      permission: 'Auth/permission'
    }),
    namePath () {
      return this.$route.fullPath
    },
    domainName () {
      const host = window.location.hostname
      const parts = host.split('.')
      if (parts.length === 3) {
        return `${parts[1]}.${parts[2]}`
      } if (parts.length === 2) {
        return `${parts[0]}.${parts[1]}`
      }
      return parts[0]
    },
    isShipnity () {
      return this.domainName === 'shipnity.com' || this.domainName === 'localhost'
    }
  },
  created () {
    setTimeout(() => {
      this.setLoading(false)
    }, 300)
    this.settingLists = [
      {
        id: '1',
        title: 'shopsetting.pages.order.title',
        icon: '$order',
        to: '/shop-setting/order',
        description: [
          { text: 'shopsetting.pages.order.description1' },
          { text: 'shopsetting.pages.order.description2' },
          { text: 'shopsetting.pages.order.description3' },
          { text: 'shopsetting.pages.order.description4' }
        ],
        show: this.permission.settingOrderPage
      },
      {
        id: '2',
        title: 'shopsetting.pages.language.title',
        icon: '$language',
        to: '/shop-setting/language',
        description: [
          { text: 'shopsetting.pages.language.description1' },
          { text: 'shopsetting.pages.language.description2' },
          { text: 'shopsetting.pages.language.description3' }
        ],
        show: this.permission.settingI18n
      },
      {
        id: '3',
        title: 'shopsetting.pages.packing.title',
        icon: '$packing',
        to: '/shop-setting/packing',
        description: [
          { text: 'shopsetting.pages.packing.description1' },
          { text: 'shopsetting.pages.packing.description2' },
          { text: 'shopsetting.pages.packing.description3' }
        ],
        show: this.permission.settingPacking
      },
      {
        id: '4',
        title: 'shopsetting.pages.warehouse.title',
        icon: '$warehouse',
        to: '/shop-setting/warehouse',
        description: [
          { text: 'shopsetting.pages.warehouse.description1' },
          { text: 'shopsetting.pages.warehouse.description2' }
        ],
        show: this.permission.settingStock
      },
      {
        id: '5',
        title: 'shopsetting.pages.product.title',
        icon: '$product',
        to: '/shop-setting/product',
        description: [
          { text: 'shopsetting.pages.product.description1' },
          { text: 'shopsetting.pages.product.description2' },
          { text: 'shopsetting.pages.product.description3' }
        ],
        show: this.permission.settingProduct
      },
      {
        id: '6',
        title: 'shopsetting.pages.payment.title',
        icon: '$payment',
        to: '/shop-setting/payment',
        description: [
          { text: 'shopsetting.pages.payment.description1' },
          { text: 'shopsetting.pages.payment.description2' }
        ],
        show: true && this.isShipnity
      },
      {
        id: '7',
        title: 'shopsetting.pages.taxinvoice.title',
        icon: '$taxinvoice',
        to: '/shop-setting/tax-invoice',
        description: [
          { text: 'shopsetting.pages.taxinvoice.description1' },
          { text: 'shopsetting.pages.taxinvoice.description2' },
          { text: 'shopsetting.pages.taxinvoice.description3' }
        ],
        show: this.permission.settingVat
      },
      {
        id: '8',
        title: 'shopsetting.pages.promocode.title',
        icon: '$promocode',
        to: '/shop-setting/promocode',
        description: [
          { text: 'shopsetting.pages.promocode.description1' }
        ],
        show: this.permission.settingPromoCode
      },
      {
        id: '9',
        title: 'shopsetting.pages.crm.title',
        icon: '$crm',
        to: '/shop-setting/crm',
        description: [
          { text: 'shopsetting.pages.crm.description1' }
        ],
        show: this.permission.settingCrm
      }
    ]
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    })
  }
}
</script>
