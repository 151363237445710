var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop-setting" },
    [
      _c("header-label", {
        attrs: {
          label: _vm.$t("shopsetting.header"),
          "prepend-icon": "$g_shopsetting"
        }
      }),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap" },
        [
          _vm._l(_vm.settingLists, function(setting) {
            return [
              setting.show
                ? _c(
                    "v-card",
                    {
                      key: setting.id,
                      staticClass: "mb-2 mr-2",
                      attrs: { to: setting.to, width: "198", flat: "" }
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mb-4",
                                  attrs: { color: "primary", size: "64" }
                                },
                                [_vm._v(" " + _vm._s(setting.icon) + " ")]
                              ),
                              _c("p", { staticClass: "title" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t(setting.title)) + " "
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._l(setting.description, function(item, index) {
                            return _c(
                              "p",
                              {
                                key: "shop_setting_description-" + index,
                                staticClass: "caption mb-1"
                              },
                              [_vm._v(" - " + _vm._s(_vm.$t(item.text)) + " ")]
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }